/* eslint-disable import/no-anonymous-default-export */
const Input = {
  Task: 'task',
  ZipCode: 'zip_code',
  BusinessClient: 'business_client',
  Date: 'date',
  Time: 'time',
  Pickup: 'pickup',
  PickupDate: 'pickup-date',
  PickupTime: 'pickup-time',
  Frequency: 'frequency',
  Vehicle: 'vehicle',
  Junk: 'products',
  Dumpster: 'services',
  Description: 'description',
  Stairs: 'stairs',
  Dismantling: 'dismantling',
  PickupAddress: 'address',
  PickupAddressAdditional: 'address_aditional',
  TermsOfService: 'tos_checked',
  UnderstandPricing: 'understand_pricing_checked',
  PaymentMethod: 'payment_method',
  PaymentFirstName: 'first_name',
  PaymentLastName: 'last_name',
  PaymentEmail: 'email',
  PaymentPhone: 'phone_number',
  TaskImages: 'taskImages',
  CompanyName: 'companyName',
}

const Task = {
  JunkRemoval: 'junk-removal',
  CardboardRemoval: 'cardboard-removal',
  DumpsterRental: 'dumpster-rental',
  PowerWashing: 'power-washing',
}

const Frequency = {
  Once: 'once',
  Weekly: 'weekly',
  Monthly: 'monthly',
}

const Pickup = {
  NextBooking: 'next-booking',
  ThreeDays: '3-days-later',
  SevenDays: '7-days-later',
  Manual: 'manual',
}

const Vehicle = {
  SUV: 'SUV',
  Truck: 'truck',
  PickupTruck: 'pick-up-truck',
}

const Payment = {
  ACH: 'ACH',
  Card: 'card',
  Check: 'check',
  Transfer: 'transfer',
  Wallet: 'wallet',
}

const defaultValues = {
  [Input.Task]: Task.JunkRemoval,
  [Input.BusinessClient]: false,
  [Input.Frequency]: Frequency.Once,
  [Input.Pickup]: Pickup.NextBooking,
  [Input.Vehicle]: Vehicle.PickupTruck,
  [Input.Junk]: [],
  [Input.Dumpster]: [],
  [Input.TermsOfService]: false,
  [Input.UnderstandPricing]: false,
  [Input.PaymentMethod]: Payment.Card,
}

const vehicles = {
  [Vehicle.SUV]: {
    id: '',
    description: Vehicle.SUV,
    price: 0,
  },
  [Vehicle.PickupTruck]: {
    id: '',
    description: Vehicle.PickupTruck,
    price: 1500,
  },
  [Vehicle.Truck]: {
    id: '',
    description: Vehicle.Truck,
    price: 3000,
  },
}

const services = {
  [Task.JunkRemoval]: {
    id: '',
    description: Task.JunkRemoval,
    price: 7000,
  },
  [Task.CardboardRemoval]: {
    id: '',
    description: Task.CardboardRemoval,
    price: 6000,
  },
  [Task.DumpsterRental]: {
    id: '',
    description: Task.DumpsterRental,
    price: 0,
  },
}

export default {
  services,
  vehicles,
  defaultValues,
  Input,
  Task,
  Frequency,
  Pickup,
  Vehicle,
  Payment,
}
