/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react'
import { MdLocationOn } from 'react-icons/md'
import parse from 'autosuggest-highlight/parse'
import { useEffect, useMemo, useRef, useState } from 'react'
import classes from '@src/styles/AddressField.module.css'
import debounce from '@src/functions/debounce'
import SelectInput from './SelectInput'

const GOOGLE_MAPS_API_KEY = process.env.NEXT_PUBLIC_GMAPS_API_KEY

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return
  }

  const script = document.createElement('script')
  script.setAttribute('async', '')
  script.setAttribute('id', id)
  script.src = src
  position.appendChild(script)
}

const autocompleteService = { current: null }

interface MainTextMatchedSubstrings {
  offset: number
  length: number
}
interface StructuredFormatting {
  main_text: string
  secondary_text: string
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[]
}
interface PlaceType {
  place_id: string
  description: string
  structured_formatting: StructuredFormatting
}

function PlacesField({
  setPlace,
  resultTypes,
  paramProps,
  loading,
}: {
  setPlace: (place: any) => void
  resultTypes: any
  paramProps?: any
  loading?: any;
}) {
  const urlAddressField = paramProps?.dynamicProps?.address ?? null;
  const [value, setValue] = useState<PlaceType | null>(
    urlAddressField?.placeId
      ? {
          place_id: urlAddressField?.placeId,
          description: urlAddressField?.location,
          structured_formatting: {
            main_text: urlAddressField?.location,
            secondary_text: '',
          },
        }
      : null,
  )
  const [inputValue, setInputValue] = useState(urlAddressField?.location ?? '')
  const [options, setOptions] = useState<readonly PlaceType[]>([])
  const loaded = useRef(false)

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      )
    }

    loaded.current = true
  };

  const handleSelect = (option: any) => {
    setValue(option);
    setInputValue(option.description)
    setOptions([]);
  }

  useEffect(() => {
    if (!urlAddressField?.placeId) return;
    setValue({
      place_id: urlAddressField?.placeId,
      description: urlAddressField?.location,
      structured_formatting: {
        main_text: urlAddressField?.location,
        secondary_text: '',
      },
    })
    setInputValue(urlAddressField?.location);
  }, [urlAddressField?.placeId]);

  const debouncedFetch = useMemo(
    () =>
      debounce((request: { input: string }, callback: (results?: readonly PlaceType[]) => void) => {
        (autocompleteService.current as any).getPlacePredictions(
          {
            ...request,
            types: resultTypes,
            componentRestrictions: {
              country: ['us'],
            },
          },
          callback,
        )
      }, 400),
    [],
  )

  useEffect(() => {
    let active = true

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService()
    }
    if (!autocompleteService.current) {
      return undefined
    }

    if (inputValue === '') {
      setOptions([])
      return undefined
    }

    debouncedFetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = []

        if (results) {
          newOptions = [...newOptions, ...results]
        }

        setOptions(newOptions)
      }
    })

    return () => {
      active = false
    }
  }, [value, inputValue, fetch])

  useEffect(() => {
    setPlace(value ?? null)
  }, [value?.place_id]);

  const PlacesOption = ({ option }: any) => {
    const matches = option.structured_formatting.main_text_matched_substrings || []
    const parts = parse(
      option.structured_formatting.main_text,
      matches.map((match: any) => [match.offset, match.offset + match.length]),
    )
    const prepSelect = (e: any) => {
      e.stopPropagation();
      handleSelect(option);
    }
    return (
      <div key={option.place_id} onClick={prepSelect}>
        <div style={{ alignItems: "center", display: "grid", gridTemplateColumns: "max-content auto" }}>
          <div style={{ display: 'flex', width: 44 }}>
            <MdLocationOn />
          </div>
          <div style={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
            {parts.map((part: any) => (
              <span
                key={part.text}
                style={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
              >
                {part.text}
              </span>
            ))}
            <body color='text.secondary'>
              {option.structured_formatting.secondary_text}
            </body>
          </div>
        </div>
      </div>
    )
  }


  return (
    <SelectInput
      placeholder="Search your zipcode"
      value={value}
      inputValue={inputValue}
      options={options}
      onChange={(v: string) => setInputValue(v)}
      onSelect={handleSelect}
      classes={{
        input: classes.placesInput,
      }}
      renderOption={(option: any) => (
        <PlacesOption option={option} key={option.place_id} />
      )}
    />
  )
}

export default PlacesField;
