import { getGclid } from "./gclid";

const initiateSegment = () => {
  return "";
};

export default initiateSegment;

export const segmentTrack = (name: string, properties?: any) => {
  if (typeof window !== "undefined") {
    const wind: any = window;
    wind?.analytics?.track(name, {
      ...(properties ?? {}),
      href: wind?.location?.href,
      version: "next-js",
      gclid: getGclid()
    });
    wind?.dataLayer?.push({
      event: name?.split(" ")?.join("_")?.toLowerCase(),
      ...(properties ?? {}),
      href: wind?.location?.href,
      version: "next-js",
      gclid: getGclid()
    })
  }
};
