function debounce<F extends (...args: any[]) => void>(func: F, wait: number): F {
  let timeoutId: NodeJS.Timeout | null

  // eslint-disable-next-line func-names
  const debouncedFunc = function(this: any, ...args: any[]) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    clearTimeout(timeoutId!)

    timeoutId = setTimeout(() => {
      func.apply(context, args)
    }, wait)
  }

  return debouncedFunc as F
};

export default debounce;