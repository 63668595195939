import { useRef, useState, useEffect } from "react";
import cssClasses from '@src/styles/SelectInput.module.css'

export interface SelectInputProps {
  placeholder?: string;
  value: any;
  inputValue: string;
  onChange: (value: string) => void | Promise<void>;
  onSelect: (option: any) => void;
  options: any;
  renderOption?: any;
  classes?: {
    root?: any,
    input?: any,
    menu?: any,
    item?: any,
  };
  // styles?: any;
}
const SelectInput = ({
  placeholder,
  value,
  inputValue,
  onChange,
  onSelect,
  options,
  renderOption,
  classes,
  // styles = {},
}: SelectInputProps) => {
  const ref = useRef(null);
  const [anchor, setAnchor] = useState(null);
  const [disableClickAway, setDisableClickaway] = useState(false);

  const handleFocus = (e: any) => {
    setAnchor(e.currentTarget);
  };

  useEffect(() => {
    setAnchor(null);
  }, [value]);

  const show = anchor && (inputValue.length > 3 || options.length > 0);

  return (
    <>
      {show && <div className={cssClasses.clickOutside} onClick={disableClickAway ? undefined : () => setAnchor(null)} />}
      <div className={cssClasses.selectInputContainer}>
        <input
          style={{ zIndex: 3 }}
          className={classes?.input || cssClasses.selectInput}
          ref={ref}
          onFocus={handleFocus}
          placeholder={placeholder}
          value={inputValue}
          onChange={(e) => onChange(e.target.value)}
          onClick={(e) => e.stopPropagation()}
          onMouseEnter={() => setDisableClickaway(true)}
          onMouseLeave={() => setDisableClickaway(false)}
        />
        {show && (
          <div className={cssClasses.selectInputMenu} onClick={(e) => e.stopPropagation()}>
            {options.length === 0 && "Enter a zipcode"}
            {renderOption ? (
              options.map((option: any) => renderOption(option))
            ) : (
              options.map((option: any) => (
                <div
                  key={option?.value ?? option}
                  onClick={() => onSelect(option)}
                  className={classes?.item || cssClasses.selectInputItem}
                >
                  {option?.label ?? "-"}
                </div>
              ))
            )} 
          </div>
        )}
      </div>
    </>
  )
};

export default SelectInput;