const thousandSeparator = ','

const decimalSeparator = '.'

const currencyPrefix = '$'

const currencyDecimalPlaces = 2

const reverse = (givenEntry: string) => givenEntry.toString().split('').reverse().join('')

const reverseReplace = (givenEntry: string, searchValue: RegExp, replaceValue: string) =>
  reverse(reverse(givenEntry).replace(searchValue, replaceValue))

const formatThousands = (givenEntry: any) =>
  reverseReplace(givenEntry.toString(), /(\d{3})/g, `$1${thousandSeparator}`).replace(
    new RegExp(`^${thousandSeparator}`),
    '',
  )

const formatToCurrency = (givenEntry = 0, fillDecimalPlaces = true) => {
  const givenEntryInteger = parseInt(givenEntry.toString(), 10) / 100
  const splittedEntry = givenEntryInteger.toString().split('.')

  const givenEntryDecimals = (splittedEntry[1] || 0)
    .toString()
    .concat(fillDecimalPlaces ? '0'.repeat(currencyDecimalPlaces) : '')
    .substr(0, currencyDecimalPlaces)

  return currencyPrefix
    .concat(formatThousands(splittedEntry[0]))
    .concat(
      givenEntryDecimals && fillDecimalPlaces ? decimalSeparator.concat(givenEntryDecimals) : '',
    )
}

const utils = {
  formatToCurrency,
}

export default utils
