/* eslint-disable no-nested-ternary */
import { useState, useEffect } from 'react'
import classes from '@src/styles/BookingWidget.module.css'
import classNames from 'classnames'
import querystring from 'query-string'
import { useRouter } from 'next/router'
import PlacesField from '@src/pages/common/components/PlacesField'
import pitchConstants from '@src/constants/pitch';
import utils from '@src/utils/pitch'
import isPlaceValid from '@src/functions/is-place-valid'
import { segmentTrack } from '@src/functions/segment'
import { getGclid } from '@src/functions/gclid'

export interface BookingWidgetProps {
  isBusiness?: boolean
  paramProps?: {
    dynamic?: any
    dynamicAddress?: any
    urlAddressField?: any
    defaultTask?: 'junk-removal' | 'cardboard-removal' | 'dumpster-rental',
    dynamicProps?: any
    content?: any
    keyword?: any
  }
}

function BookingWidget({ isBusiness, paramProps = {} }: BookingWidgetProps) {
  const history = useRouter()
  const [profile] = useState<any>({ userType: 'customer' })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [address, setAddress] = useState<any>(paramProps?.urlAddressField)
  const [error, setError] = useState<string | null>(null)
  const [valid, setValid] = useState(paramProps?.dynamicProps?.valid);
  const [loading, setLoading] = useState(false);
  const [taskType, setTaskType] = useState<
    'junk-removal' | 'cardboard-removal' | 'dumpster-rental'
  >(paramProps?.defaultTask ?? 'junk-removal');

  const isDirectToBid = isBusiness || paramProps?.content?.quoteOnly;

  const priceMultiplier = paramProps?.dynamicProps?.pricing?.priceMultipliers ?? 100;
  const lowestPrices: any = {
    "cardboard-removal": 200,
    "dumpster-rental": 32500,
    "junk-removal": 0,
  };

  useEffect(() => {
    if (!paramProps?.dynamicProps?.fetched) return;
    setAddress(paramProps?.dynamicProps?.address);
    setValid(paramProps?.dynamicProps?.valid);
    setTaskType(paramProps?.defaultTask ?? 'junk-removal');
  }, [paramProps]);

  const minimumVehicle = pitchConstants.vehicles['pick-up-truck'];
  const taskPrice = utils.formatToCurrency(
    (pitchConstants.services[taskType].price + minimumVehicle.price + lowestPrices[taskType]) *
      (priceMultiplier / 100),
  );

  const contentClasses = classNames(classes.locationSearch, {
    [classes.locationSearchBusiness]: isBusiness,
  })

  const mainContentClasses = classNames(classes.mainContent, {
    [classes.mainContentBusiness]: isBusiness,
  })

  const updateAddress = async (place: any) => {
    if (!place) {
      setLoading(false);
      setValid(false);
      return;
    }
    if (place?.place_id === paramProps?.dynamicProps?.address?.placeId) {
      setLoading(false);
      return;
    }
    setLoading(true);
    segmentTrack("Place Entered", {
      description: place.description,
      placeId: place.place_id,
    });
    const placeValue: string = place.description;
    setValid(false);
    const { data }: any = await isPlaceValid(placeValue, taskType);
    if (!data.valid) {
      segmentTrack("Location Not Served", {
        location: placeValue,
        from: "Booking Widget",
      });
      setError("Please try searching again");
      setValid(false);
      setAddress(null);
    } else {
      segmentTrack("Location Served", {
        location: placeValue,
        from: "Booking Widget",
      });
      setError(null);
      setValid(true)
      setAddress(data.address);
    }
    setLoading(false);
  }

  const handleBook = () => {
    segmentTrack(`Clicked Book - Pitch`);

    history.push(
      `${process.env.NEXT_PUBLIC_REDIRECT_URL}booking?`.concat(
        querystring.stringify({
          task: taskType,
          business_client: Number(!!isBusiness),
          bid: isDirectToBid ? '1' : '0',
          valid: valid ? '1' : '0',
          place: address?.placeId ?? "",
          redirect: "1",
          gclid: getGclid(),
          source: paramProps?.content?.source ?? "",
          keyword: paramProps?.keyword ?? "",
        }),
      ),
    )
  }

  // const disabled = error !== null || !valid

  return (
    <div className={classes.container}>
      <div className={classes.tabs}>
        <button
          onClick={() => setTaskType('junk-removal')}
          className={classNames(classes.tabButton, {
            [classes.active]: taskType === 'junk-removal',
          })}
        >
          Waste Removal
        </button>
        <button
          onClick={() => setTaskType('cardboard-removal')}
          className={classNames(classes.tabButton, {
            [classes.active]: taskType === 'cardboard-removal',
          })}
        >
          Cardboard Removal
        </button>
        <button
          onClick={() => setTaskType('dumpster-rental')}
          className={classNames(classes.tabButton, {
            [classes.active]: taskType === 'dumpster-rental',
          })}
        >
          Dumpster Rental
        </button>
      </div>
      <div className={mainContentClasses}>
        <div className={contentClasses}>
          <div>
            <p className={classes.mainText}>Check if we serve your area</p>
          </div>
          <div className={classes.mainField}>
            <PlacesField
              setPlace={updateAddress}
              resultTypes={["(regions)"]}
              paramProps={paramProps}
              loading={loading}
            />
            <p style={{ color: '#1E3B47', padding: '4px' }} className={classes.smallText}>
              {error}
            </p>
          </div>
        </div>
        {!isBusiness && (
          <div className={classes.priceSelector}>
            <p className={classes.mainText}>Service starts at</p>
            <div className={classes.price}>{taskPrice}</div>
          </div>
        )}
      </div>
      {profile?.userType !== 'provider' && (
        <button onClick={handleBook} className={classes.primaryButton}>
          {loading ? "Checking location" : isDirectToBid ? 'Get a Quote' : 'Book Now'}
        </button>
      )}
    </div>
  )
}

export default BookingWidget
