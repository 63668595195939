const isPlaceValid = async (value: string, task: string) => {
  const url = `${process.env.NEXT_PUBLIC_REDIRECT_URL}api/landing/is-place-valid`;
  const data = {
    value,
    task,
  };

  let validation: any = {
    valid: false,
  };

  const props = fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(response => response.json())
    .then(response => {
      validation = {
        ...response,
        dynamicText: "",
      };
      return {
        data: validation,
      }
    })
    .catch(error => {
      // handle error
      // eslint-disable-next-line no-console
      console.error(error);
      return {
        data: {
          ...validation,
          dynamicText: "",
        },
      }
    });
  return props;
}

export default isPlaceValid;